import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import './HomePage.css';
import northeast from '../assets/northamerica.jpg'
import midwest from '../assets/midwest.jpg'
import west from '../assets/la.jpg'
import southcentral from '../assets/southcentral.JPG'
import jamaica from '../assets/jamaica.jpg'
import southeast from '../assets/southeast.jpg'

const NorthAmerica = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination, region) => {
    navigate(`/${destination.toLowerCase()}`, { state: { region: region } });
  };

  return (
    <>
      <Helmet>
        <title>North America Travel Guide | The Half Time Travel</title>
        <meta name="description" content="Explore North America with The Half Time Travel. Discover the Northeast, Midwest, West, South Central regions, and Jamaica." />
        <meta property="og:title" content="North America Travel Guide | The Half Time Travel" />
        <meta property="og:description" content="Plan your North American adventure with The Half Time Travel. Explore diverse regions from the Northeast to Jamaica!" />
        <meta property="og:image" content={northeast} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelBlog",
            "name": "The Half Time Travel - North America",
            "description": "Explore North America with The Half Time Travel",
            "url": "https://www.thehalftimewhistle.com/northamerica",
            "image": northeast
          })}
        </script>
      </Helmet>

      <div className="home-page">
        <header className="header">
          <nav className="nav-menu">
            <ul className="menu-level-1">
              <li><Link to="/">Home</Link></li>
            </ul>
          </nav>
          <div className="banner">
            <img src={northeast} alt="North America Travel Banner" className="banner-image" loading="lazy" />
            <h1 className="banner-text">Welcome to The Half Time Travel Americas Page</h1>
          </div>
        </header>

        <main className="main-content">
          <section className="content-blocks">
            <article className="block" onClick={() => handleBlockClick('Region', 'NorthEast')}>
              <div className="block-content">
                <img src={northeast} alt="Northeast USA Travel Destinations" loading="lazy" />
                <h2>North East</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Region', 'MidWest')}>
              <div className="block-content">
                <img src={midwest} alt="Midwest USA Travel Destinations" loading="lazy" />
                <h2>Mid West</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Region', 'West')}>
              <div className="block-content">
                <img src={west} alt="West USA Travel Destinations" loading="lazy" />
                <h2>West</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Region', 'SouthCentral')}>
              <div className="block-content">
                <img src={southcentral} alt="South Central USA Travel Destinations" loading="lazy" />
                <h2>South Central</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Region', 'SouthEast')}>
              <div className="block-content">
                <img src={southeast} alt="South East USA Travel Destinations" loading="lazy" />
                <h2>South East</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Region', 'Jamaica')}>
              <div className="block-content">
                <img src={jamaica} alt="Jamaica Travel Destinations" loading="lazy" />
                <h2>Jamaica</h2>
              </div>
            </article>
          </section>
        </main>

        <footer className="footer">
          <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
          <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
        </footer>
      </div>
    </>
  );
};

export default NorthAmerica;