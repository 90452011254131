import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import Americas from "./components/NorthAmerica"
import Region from './components/Region'
import Europe from "./components/Europe"
import Asia from "./components/Asia"
import ContentDisplayOne from "./components/ContentDisplayOne"
import CentralAmerica from './components/CentralAmerica';
import Oceania from './components/Oceania';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <div className="App">
        <Routes> 
          <Route path="/" element={<HomePage />} />
          <Route path="/northamerica" element={<Americas />} />
          <Route path='/region' element={<Region />} />
          <Route path="/europe" element={<Europe />} />
          <Route path="/asia" element={<Asia />} />
          <Route path="/centralamerica" element={<CentralAmerica />} />
          <Route path="/oceania" element={<Oceania />} />
          <Route path="/contentdisplayone" element={<ContentDisplayOne />} />
          <Route path="/content-display-one/*" element={<ContentDisplayOne />} />
          <Route path="/content-display-one/:region/:title" element={<ContentDisplayOne />} />
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
}

export default App;