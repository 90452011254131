import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import './HomePage.css';
import banner from '../assets/banner.jpg'
import northamerica from '../assets/northamerica.jpg'
import centralamerica from '../assets/centralamerica.jpg'
import europe from '../assets/europe.jpg'
import asia from '../assets/asia.jpg'
import oceania from '../assets/oceania.jpg'

const HomePage = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination) => {
    navigate(`/${destination.toLowerCase()}`);
  };

  return (
    <>
      <Helmet>
        <title>The Half Time Travel Website - Family Travel Adventures</title>
        <meta name="description" content="Explore family travel adventures, itineraries, and off-the-beaten-path experiences across continents. Plan your next journey with The Half Time Travel Website." />
        <meta property="og:title" content="The Half Time Travel Website - Family Travel Adventures" />
        <meta property="og:description" content="Discover unique family travel experiences and itineraries across continents. Plan your next adventure with The Half Time Travel Website." />
        <meta property="og:image" content={banner} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelBlog",
            "name": "The Half Time Travel Website",
            "description": "Family travel adventures and itineraries across continents",
            "url": "https://www.thehalftimewhistle.com",
            "image": banner
          })}
        </script>
      </Helmet>

      <div className="home-page">
        <header className="header">
          <nav className="nav-menu">
            <ul className="menu-level-1">
              <li><Link to="/">Home</Link></li>
            </ul>
          </nav>
          <div className="banner">
            <img src={banner} alt="The Half Time Travel Website Banner" className="banner-image" loading="lazy" />
            <h1 className="banner-text">Welcome to The Half Time Travel Website</h1>
          </div>
        </header>

        <main className="main-content">
          <section className="common-block">
            <h2>Our Family Travel Philosophy</h2>
            <p>We love to travel as a family—exploring new destinations, experiencing diverse cultures, and embracing the life of true wanderers. When we plan a trip, the process of creating the itinerary is often a daunting task. We prefer to explore like locals—renting cars, staying in Airbnbs, and stepping off the beaten path.</p>
            <p>For a long time, I've wanted to create a log of all our travel experiences in one place—a space that not only serves as our family travelogue, allowing us to relive our cherished memories, but also as a resource for others. We hope that people will read about our adventures and, perhaps, feel inspired to visit some of the places we've been.</p>
            <h3>Why "Half Time Whistle"?</h3>
            <p>In any game, a whistle signals a break—a time to refuel, rejuvenate, and rethink. Our Half Time Whistle website is inspired by this concept, encouraging you to take a pause from your busy life to wander, explore, and rediscover the world.</p>
            <p>Half Time Whistle is not just a travel website; it's a piece of our heart and soul. It's a collection of our memories, woven together with passion and love. We hope you enjoy this space as much as we enjoy creating and nurturing it.</p>
            <p>Shantanu, Kapila, Shaurya, and Nitara</p>  
          </section>

          <section className="content-blocks">
            <article className="block featured-block">
              <div className="star-icon" aria-hidden="true">★</div>
              <div className="block-content">
                <img src={banner} alt="Smoky Mountains National Park" loading="lazy" />
                <h2>Coming Up - Smoky Mountains National Park - Nov'24</h2>
              </div>
            </article>

            <article className="block" onClick={() => handleBlockClick('NorthAmerica')}>
              <div className="block-content">
                <img src={northamerica} alt="North America Travel Destinations" loading="lazy" />
                <h2>North America</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('CentralAmerica')}>
              <div className="block-content">
                <img src={centralamerica} alt="Central America Travel Destinations" loading="lazy" />
                <h2>Central America</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Europe')}>
              <div className="block-content">
                <img src={europe} alt="Europe Travel Destinations" loading="lazy" />
                <h2>Europe</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Asia')}>
              <div className="block-content">
                <img src={asia} alt="Asia Travel Destinations" loading="lazy" />
                <h2>Asia</h2>
              </div>
            </article>
            <article className="block" onClick={() => handleBlockClick('Oceania')}>
              <div className="block-content">
                <img src={oceania} alt="Oceania Travel Destinations" loading="lazy" />
                <h2>Oceania</h2>
              </div>
            </article>
          </section>
        </main>

        <footer className="footer">
          <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
          <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
        </footer>
      </div>
    </>
  );
};

export default HomePage;