import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import './ContentDisplayOne.css';
import './HomePage.css';
import './ComingSoon.css';

const ContentDisplayOne = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [region, setRegion] = useState('');
  const [title, setTitle] = useState('');
  const [placeDetails, setPlaceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const carouselRef = useRef(null);
  const [originalTitle, setOriginalTitle] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupImages, setPopupImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    let newRegion = '';
    let newTitle = '';
    if (params.region && params.title) {
      newRegion = decodeURIComponent(params.region);
      newTitle = decodeURIComponent(params.title);
    } else if (location.state && location.state.region && location.state.title) {
      newRegion = location.state.region;
      newTitle = location.state.title;
    } else if (location.pathname) {
      const pathParts = location.pathname.split('/').filter(Boolean);
      if (pathParts.length >= 2) {
        newRegion = decodeURIComponent(pathParts[pathParts.length - 2]);
        newTitle = decodeURIComponent(pathParts[pathParts.length - 1]);
      }
    }
    setRegion(newRegion);
    setTitle(newTitle);
    if (!originalTitle) {
      setOriginalTitle(newTitle);
    }
  }, [params.region, params.title, location.state, location.pathname]);

  useEffect(() => {
    const fetchPlaceDetails = async () => {
      if (!region || !title) {
        setError('Region or title is missing');
        setIsLoading(false);
        return;
      }
      try {
        const encodedRegion = encodeURIComponent(region);
        const encodedTitle = encodeURIComponent(title);
        const url = `/api/placedetails/${encodedRegion}/${encodedTitle}`;
        setError(`Fetching from URL: ${url}`); // Display the URL being fetched
        const response = await fetch(url);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }
        const data = await response.json();
        setPlaceDetails(data);
        setError(null);
      } catch (e) {
        console.error('Fetch error:', e);
        setError(`Failed to fetch details for ${title} in ${region}. Error: ${e.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (region && title) {
      fetchPlaceDetails();
    }
  }, [region, title]);

  const getImagePath = (imageName) => {
    if (!imageName) return '';
    try {
      return require(`../assets/${imageName}`);
    } catch (err) {
      console.error(`Error loading image: ${imageName}`, err);
      return '';
    }
  };

  const scrollCarousel = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleImageClick = (imageTitle) => {
    const encodedRegion = encodeURIComponent(placeDetails.region);
    const encodedTitle = encodeURIComponent(imageTitle);
    navigate(`/content-display-one/${encodedRegion}/${encodedTitle}`, { state: { originalTitle: originalTitle || title } });
  };

  const handleBackClick = () => {
    if (originalTitle && originalTitle !== title) {
      const encodedRegion = encodeURIComponent(region);
      const encodedOriginalTitle = encodeURIComponent(originalTitle);
      navigate(`/content-display-one/${encodedRegion}/${encodedOriginalTitle}`, { replace: true });
    } else {
      navigate(-1);
    }
  };

  const openPopup = (images, startIndex) => {
    setPopupImages(images);
    setCurrentImageIndex(startIndex);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % popupImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + popupImages.length) % popupImages.length);
  };

  const handleDayImageClick = (day, imgIndex) => {
    const images = Object.values(day.images);
    openPopup(images, imgIndex);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div style={{color: 'red', padding: '20px'}}>{error}</div>;
  if (!placeDetails) return <div>No details found</div>;

  return (
    <div>
      <header className="header">
        <nav className="nav-menu">
          <ul className="menu-level-1">
            <li><a href="/">Home</a></li>
          </ul>
        </nav>
      </header>
      <div className="content-display">
        <h1>{region || "Sample"} Region</h1>
        <button onClick={handleBackClick} className="back-button">
          Back to {originalTitle || "Previous"} Page
        </button>
        <div className="place-details">
          <div className="image-container">
            {placeDetails.image && (
              <img src={getImagePath(placeDetails.image)} alt={placeDetails.title} className="main-image" />
            )}
            <h1 className="image-title">{placeDetails.title}</h1>
          </div>
          {placeDetails.imageBlock && placeDetails.imageBlock.length > 0 && (
            <div className="image-block-carousel">
              <button onClick={() => scrollCarousel('left')} className="carousel-button left">&lt;</button>
              <div className="carousel" ref={carouselRef}>
                {placeDetails.imageBlock.map((item, index) => (
                  <div key={index} className="carousel-item">
                    <img
                      src={getImagePath(item.image)}
                      alt={item.title}
                      className="carousel-image clickable"
                      onClick={() => handleImageClick(item.title)}
                    />
                    <h3 className="carousel-title">{item.title}</h3>
                  </div>
                ))}
              </div>
              <button onClick={() => scrollCarousel('right')} className="carousel-button right">&gt;</button>
            </div>
          )}
          <div className="main-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(placeDetails.description) }} />
        </div>
        {placeDetails.days && placeDetails.days.map((day, index) => (
          <div key={day._id || index} className="day-block">
            <h2>{day.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(day.description) }} />
            {day.images && Object.keys(day.images).length > 0 && (
              <div className="image-gallery">
                {Object.values(day.images).map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={getImagePath(image)}
                    alt={`Day ${index + 1} - Image ${imgIndex + 1}`}
                    onClick={() => handleDayImageClick(day, imgIndex)}
                    className="clickable"
                    onError={(e) => {
                      console.error(`Failed to load image: ${image}`);
                      e.target.style.display = 'none';
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
        {placeDetails.commonBlocks && placeDetails.commonBlocks.map((block, index) => (
          <div key={block._id || index} className="common-block">
            <h3>{block.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.description) }} />
          </div>
        ))}
      </div>
      {showPopup && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <img src={getImagePath(popupImages[currentImageIndex])} alt={`Popup image ${currentImageIndex + 1}`} className="popup-image" />
            <button className="popup-close" onClick={closePopup}>&times;</button>
            <button className="popup-nav prev" onClick={prevImage}>&lt;</button>
            <button className="popup-nav next" onClick={nextImage}>&gt;</button>
          </div>
        </div>
      )}
      <footer className="footer">
        <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
        <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
      </footer>
    </div>
  );
};

export default ContentDisplayOne;
