import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './HomePage.css';
import './ItemsStyle.css';

const Region = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const region = location.state?.region || 'NorthEast';
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => { 
    fetchPlaces();
  }, [region]);

  const fetchPlaces = async () => {
    try {
      const response = await fetch(`/api/places/${encodeURIComponent(region.toLowerCase())}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (!Array.isArray(data.data)) {
        throw new Error('Data is not an array');
      }

      setPlaces(data.data);
      setError(null);
    } catch (e) {
      console.error('Fetch error:', e);
      setError(`Failed to fetch places for ${region}. Error: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceSelect = (place) => {
    navigate('/ContentDisplayOne', { 
      state: { 
        region: region,
        title: place.title,
        id: place._id
      } 
    });
  };

  const PlaceComponent = ({ place }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
      const loadImage = async () => {
        try {
          const imageModule = await import(`../assets/${place.image}`);
          setImageSrc(imageModule.default);
        } catch (err) {
          console.error(`Error loading image: ${place.image}`, err);
          setImageSrc(null);
        }
      };

      if (place.image) {
        loadImage();
      }
    }, [place.image]);

    return (
      <article className="item" onClick={() => handlePlaceSelect(place)}>
        <h2>{place.title || 'No Title'}</h2>
        <div className="item-content">
          {imageSrc ? (
            <img src={imageSrc} alt={place.title || 'No Title'} loading="lazy" />
          ) : (
            <div>Image not available</div>
          )}
          <p>{place.description || 'No Description'}</p>
        </div>
      </article>
    );
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Helmet>
        <title>{`${region} Travel Guide | The Half Time Travel`}</title>
        <meta name="description" content={`Explore ${region} with The Half Time Travel. Discover amazing destinations and travel tips.`} />
        <meta property="og:title" content={`${region} Travel Guide | The Half Time Travel`} />
        <meta property="og:description" content={`Plan your ${region} adventure with The Half Time Travel. Explore top destinations and hidden gems!`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelBlog",
            "name": `The Half Time Travel - ${region}`,
            "description": `Explore ${region} with The Half Time Travel`,
            "url": `https://www.thehalftimewhistle.com/${region.toLowerCase()}`
          })}
        </script>
      </Helmet>

      <div className="home-page">
        <header className="header">
          <nav className="nav-menu">
            <ul className="menu-level-1">
              <li><Link to="/">Home</Link></li>
            </ul>
          </nav>
        </header>
        <main>
          <h1>{region} Region</h1>
          <section className="items-container">
            {places.length > 0 ? (
              places.map((place) => (
                <PlaceComponent
                  key={place._id}
                  place={place}
                />
              ))
            ) : (
              <p>No places found for {region}</p>
            )}
          </section>
        </main>
        <footer className="footer">
          <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
          <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
        </footer>
      </div>
    </>
  );  
};

export default Region;