import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import './HomePage.css';
import banner from '../assets/europe.jpg'
import italy from '../assets/italy.jpg'
import france from '../assets/france.jpg'
import monaco from '../assets/monaco.jpg'
import england from '../assets/england.jpg'
import Iceland from '../assets/iceland.jpg'

const Europe = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination, region) => {
    navigate(`/${destination.toLowerCase()}`, { state: { region: region } });
  };

  return (
    <>
      <Helmet>
        <title>Europe Travel Guide | The Half Time Travel</title>
        <meta name="description" content="Explore Europe with The Half Time Travel. Discover Italy, France, Monaco, England, Iceland, and more amazing destinations across the continent." />
        <meta property="og:title" content="Europe Travel Guide | The Half Time Travel" />
        <meta property="og:description" content="Plan your European adventure with The Half Time Travel. Explore Italy, France, Monaco, England, Iceland, and more!" />
        <meta property="og:image" content={banner} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelBlog",
            "name": "The Half Time Travel - Europe",
            "description": "Explore Europe with The Half Time Travel",
            "url": "https://www.thehalftimewhistle.com/europe",
            "image": banner
          })}
        </script>
      </Helmet>

      <div className="home-page">
        <header className="header">
          <nav className="nav-menu">
            <ul className="menu-level-1">
              <li><Link to="/">Home</Link></li>
            </ul>
          </nav>
          <div className="banner">
            <img src={banner} alt="Europe Travel Banner" className="banner-image" loading="lazy" />
            <h1 className="banner-text">Welcome to The Half Time Travel Europe Page</h1>
          </div>
        </header>

        <main className="main-content">
          <section className="content-blocks">
            <article className="block" onClick={() => handleBlockClick('Region', 'Italy')}>
              <div className="block-content">
                <img src={italy} alt="Italy Travel Destinations" loading="lazy" />
                <h2>Italy</h2>
              </div>
            </article>

            <article className="block" onClick={() => handleBlockClick('Region', 'France')}>
              <div className="block-content">
                <img src={france} alt="France Travel Destinations" loading="lazy" />
                <h2>France</h2>
              </div>
            </article>

            <article className="block" onClick={() => handleBlockClick('Region', 'Monaco')}>
              <div className="block-content">
                <img src={monaco} alt="Monaco Travel Destinations" loading="lazy" />
                <h2>Monaco</h2>
              </div>
            </article>

            <article className="block" onClick={() => handleBlockClick('Region', 'England')}>
              <div className="block-content">
                <img src={england} alt="England Travel Destinations" loading="lazy" />
                <h2>England</h2>
              </div>
            </article>

            <article className="block" onClick={() => handleBlockClick('Region', 'Iceland')}>
              <div className="block-content">
                <img src={Iceland} alt="Iceland Travel Destinations" loading="lazy" />
                <h2>Iceland</h2>
              </div>
            </article>
          </section>
        </main>

        <footer className="footer">
          <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
          <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
        </footer>
      </div>
    </>
  );
};

export default Europe;