import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import './HomePage.css';
import banner from '../assets/centralamerica.jpg'
import costarica from '../assets/costarica.jpg'

const CentralAmerica = () => {
  const navigate = useNavigate();

  const handleBlockClick = (destination, region) => {
    navigate(`/${destination.toLowerCase()}`, { state: { region: region } });
  };

  return (
    <>
      <Helmet>
        <title>Central America Travel Guide | The Half Time Travel</title>
        <meta name="description" content="Explore Central America with The Half Time Travel. Discover Costa Rica and other amazing destinations in this diverse region." />
        <meta property="og:title" content="Central America Travel Guide | The Half Time Travel" />
        <meta property="og:description" content="Plan your Central American adventure with The Half Time Travel. Explore Costa Rica and more!" />
        <meta property="og:image" content={banner} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelBlog",
            "name": "The Half Time Travel - Central America",
            "description": "Explore Central America with The Half Time Travel",
            "url": "https://www.thehalftimewhistle.com/centralamerica",
            "image": banner
          })}
        </script>
      </Helmet>

      <div className="home-page">
        <header className="header">
          <nav className="nav-menu">
            <ul className="menu-level-1">
              <li><Link to="/">Home</Link></li>
            </ul>
          </nav>
          <div className="banner">
            <img src={banner} alt="Central America Travel Banner" className="banner-image" loading="lazy" />
            <h1 className="banner-text">Welcome to The Half Time Travel Central America Page</h1>
          </div>
        </header>

        <main className="main-content">
          <section className="content-blocks">
            <article 
              className="block" 
              onClick={() => handleBlockClick('Region', 'costarica')}
            >
              <div className="block-content">
                <img src={costarica} alt="Costa Rica Travel Destinations" loading="lazy" />
                <h2>Costa Rica</h2>
              </div>
            </article>
          </section>
        </main>

        <footer className="footer">
          <p>&copy; 2024 TheHalfTimeTravel and TheHalfTimeWhistle Website. All rights reserved.</p>
          <p>Disclaimer: All images and content are our personal opinion and should be read and viewed from that perspective</p>
        </footer>
      </div>
    </>
  );
};

export default CentralAmerica;